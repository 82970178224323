import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Relift from "../../../images/media/products/relift/relift.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Anti-aging face day serum SPF 15 - Relift" lang={"EN"} />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="magenta">
                    <H1>Anti-aging face day serum SPF 15</H1>
                    <p>Lifting & Firming Vitamin Boost <br/>for Mature Skin </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/relift/products/odmladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Anti-aging face day serum SPF 15"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>

                    <ul>
                        <li>Smoothes fines lines and wrinkles for reduced appearance of these ageing signs. </li>
                        <li>Firms and conditions.</li>
                        <li>Oxygenates and stimulates to replenish skin cells. </li>
                        <li>Enhances microbiome and brings natural protective skin barrier to life.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-odmladzajace-serum-do-twarzy-na-dzien-50-ml-000000000000407513.html" color="magenta">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-relift-odmladzajace-serum-do-twarzy-na-dzien-spf-15-50-ml.html" color="magenta">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-relift-vitamin-a-odmladzajace-serum-do-twarzy-na-dzien-spf-15-50-ml-0107790" color="magenta">Buy in Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176929-Oxygenetic_Relift_Vitamin_A_odmladzajace_serum_do_twarzy_na_dzien_SPF_15_50_ml" color="magenta">Buy in DOZ</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/relift/products/odmladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="magenta" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/smoothing-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/wygladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="Smoothing eye serum"
                        />
                    </Link>
                    <Link to="/en/products/lifting-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/liftingujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="liftingujące serum"
                        />
                    </Link>
                    <Link to="/en/products/ultra-repair-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="ultranaprawcze serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="magenta">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="magenta">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">


                                <p>
                                    <strong>Oxygenetic rejuvenating face day serum SPF 15</strong>
                                </p>
                                <p>
                                    This pro-youth serum reduces sign of ageing, from sagging skin to fine lines to wrinkles.
                                </p>
                                <p>
                                    With increased collagen and elastin synthesis  plumps and firms the skin to redefine facial contours for truly more youthful look. Slows down ageing and photoageing processes by preventing free radicals and UV radation against damaging your skin.
                                </p>
                                <p>
                                    Binds to epidermal lipids and stimulates fibroblast proliferation to resurface, restore and condition the skin. All this will give your skin young look, smooth, firm and deeply nourished skin.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/relift/products/odmladzajace-serum_single.png"
                                loading="lazy"
                                // width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="odmladzajace serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use:</strong></p>
                                <p> Use daily in the morning. Cleanse the skin before application. For external use only. Avoid direct contact with eyes.
</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <Relift/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Retinol with adenosine" desc="is a golden standard for anti-ageing, the combination that works effectively to replenish skin cells by allowing new plump skin cells to form. Stimulates collagen production  to keep the skin looking youthful. Trusted for real results. Demonstrated a sinificant reduction in the appearance of wrinkles by strengthening fibroblast proliferation in the dermal layer and promoting collagen synthesis. You will surely notice smooth, firmed and refined complexion" />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="OBFN" desc="The Oriental Beauty Fruits Newplex of pomegranate, fig, mulberry, and ginkgo to improve skin bounce and elasticity and smooth out fine lines and wrinkles. Rich in phanolic acids and flavonoids, antioxidants to help protect the skin from free radical damage and resilient to ageing. " />
                    <ActiveIngredient name="Macadamia nut oil" desc="Macadamia nut oil has a chemical profile similar to sebum, i.e. the fatty acid secreted by the skin that makes the skin plump and dewy, which decreases with age. This ingredient repels water causing droplets to form known as hydrophobic which create coating on the skin to ensure moisture, softness and smoothness to enjoy plump and dewy skin." />
                    <ActiveIngredient name="Panthenol" desc="Provitamin B5, which penetrates into the deeper layers of the skin, moisturizes it and soothes irritations. Additionally, it reduces transepidermal water loss (TEWL) and restores hydrolipid balance." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="magenta" percentage="100" desc="badanych potwierdza, że po zastosowaniu skóra odzyskała zdrowy i promienny wygląd"/>
                    <EffectivnessCard color="magenta" percentage="87" desc="badanych potwierdza, że regularne stosowanie widocznie poprawia kontur i owal twarzy"/>
                    <EffectivnessCard color="magenta" percentage="97" desc="badanych potwierdza, że rozjaśnia drobne przebarwienia poprawiając koloryt skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
